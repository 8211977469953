import React from "react"
import Img from "gatsby-image"
import Layout from '../../components/layout'
//import graphql from 'gatsby'

const printContent = (node) => {
  if (node.frontmatter.slug.includes("cnt/community")) {
    return (
      <li className="news-item">
        <h3 className="header">{ node.frontmatter.title }</h3>
        { node.frontmatter.image != null &&
              <span className="news-img"><Img fluid={node.frontmatter.image.childImageSharp.fluid} /></span>
            }
        <div className="content" dangerouslySetInnerHTML={{ __html: node.html }} />
      </li>
    )
  }
  else {
    return (<li />)
  }
}

const LocalizedPageCNT = ({data}) => (
  <Layout>
     <div id="content-wrap">
      <ul id="news" class="community">
        { data.allMarkdownRemark.edges.map(({ node }) => ( printContent(node) ))}
      </ul>
    </div>
  </Layout>
)

export default LocalizedPageCNT

export const pageQuery = graphql`
  query {
    allMarkdownRemark (sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            image {
              childImageSharp {
                fluid (maxWidth: 792, maxHeight: 387) {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
          }
          html
        }
      }
    }
  }
`